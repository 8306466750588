<template>
    <component
        :is="buttonComponent"
        class="button-subheader cursor-pointer"
        type="button"
        :class="buttonClasses"
        :disabled="buttonDisabled"
        :to="buttonRoute"
        v-bind="buttonHref ? { href: buttonHref } : {}"
        @click="buttonClick(buttonClickParams)"
    >
        <span :class="iconClasses">
            <component
                v-if="buttonIcon"
                :is="buttonIcon"
                :marginAuto="false"
                :size="5"
            ></component>
            <span>{{ buttonText }}</span>
        </span>
    </component>
</template>

<script>
export default {
    name: 'ButtonSubheader',
    props: {
        buttonDisabled: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
        buttonRoute: {
            required: false,
            type: [String, Object],
            default: null,
        },
        buttonHref: {
            required: false,
            type: String,
        },
        buttonText: {
            required: false,
            type: String,
            default: null,
        },
        buttonIcon: {
            required: false,
            type: String,
            default: null,
        },
        buttonClick: {
            required: false,
            type: Function,
            default: () => {},
        },
        buttonClickParams: {
            required: false,
            type: [String, Object, Array],
            default: null,
        },
        flip: {
            required: false,
            type: Boolean,
            default: false,
        },
        space: {
            required: false,
            type: Boolean,
            default: true,
        },
        dark: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    computed: {
        buttonComponent () {
            if (this.buttonRoute) return 'router-link';
            if (this.buttonHref) return 'a';
            return 'button';
        },
        buttonClasses () {
            const cls = {};
            cls['flex-column flex items-center'] = true;
            cls['text-base font-regular'] = true;
            cls['cursor-pointer'] = true;
            cls['bg-transparent text-black'] = !this.dark;
            cls['bg-transparent text-white'] = !!this.dark;
            cls['disabled:bg-zinc-500'] = !!this.buttonDisabled;
            return cls;
        },
        iconClasses () {
            const cls = {};
            cls['flex items-center'] = !!this.buttonIcon;
            cls['space-x-2'] = !!this.space && !!this.buttonIcon;
            cls['flex-row'] = !this.flip && !!this.buttonIcon;
            cls['flex-row-reverse'] = !!this.flip && !!this.buttonIcon;
            return cls;
        },
    },
};
</script>
