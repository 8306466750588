<template>
    <div class="app-subheader bg-zinc-100" :class="subheaderClasses" v-if="title">
        <LayoutContained>
            <div class="flex h-12 flex-row items-stretch space-x-3">
                <ButtonSubheader
                    :buttonText="hasParent ? parent.title : title"
                    :buttonRoute="hasParent ? parent.route : $route"
                    :dark="dark"
                ></ButtonSubheader>
                <div class="flex-column flex items-center" :class="dark ? 'text-white' : 'text-black'">
                    <IconChevronRight :size="4"></IconChevronRight>
                </div>
                <ButtonSubheader
                    v-if="hasParent"
                    :buttonText="title"
                    :buttonRoute="$route"
                    :dark="dark"
                ></ButtonSubheader>
                <div class="grow"></div>
                <slot></slot>
            </div>
        </LayoutContained>
    </div>
</template>

<script>
export default {
    name: 'AppSubheader',
    props: {
        title: {
            required: true,
            type: String,
            default: null,
        },
        parent: {
            required: false,
            type: Object,
            default: null,
        },
        dark: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasParent () {
            return this.parent && this.parent.title && this.parent.route;
        },
        subheaderClasses () {
            const cls = {};
            cls['bg-zinc-100'] = !this.dark;
            cls['bg-zinc-900'] = !!this.dark;
            return cls;
        },
    },
};
</script>
